import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "./initialState";

export const dataClassificationSlice = createSlice({
  name: "dataclassification",
  initialState,
  reducers: {
    setDocClassificationUpdated: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDocClassificationUpdated = payload;
    },
    setPdfBoxSize: (state, { payload }: PayloadAction<number>) => {
      state.pdfBoxSize = payload;
    },
    setDocumentClassificationSideMenu: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.dataClassificationPdfSideMenu = payload;
    },
    setPdfPageNumber: (state, { payload }: PayloadAction<any>) => {
      state.pdfPageNumber = payload;
    },
    setUpdateDocList: (state, { payload }: PayloadAction<boolean>) => {
      state.isDocListUpdated = payload;
    },
  },
});

export const {
  setDocClassificationUpdated,
  setPdfBoxSize,
  setDocumentClassificationSideMenu,
  setPdfPageNumber,
  setUpdateDocList,
} = dataClassificationSlice.actions;

export default dataClassificationSlice.reducer;
