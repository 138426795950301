export interface ClassificationState {
  isDocClassificationUpdated: boolean;
  pdfBoxSize: number;
  dataClassificationPdfSideMenu: boolean;
  pdfPageNumber: any;
  isDocListUpdated: boolean;
}

// Define the initial state using that type
export const initialState: ClassificationState = {
  isDocClassificationUpdated: false,
  pdfBoxSize: 210,
  dataClassificationPdfSideMenu: false,
  pdfPageNumber: null,
  isDocListUpdated: false,
};
