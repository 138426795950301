import axios from "axios"
import { SessionStorageService } from "../services/session-storage/session-storage"

export const setupInterceptors = () => {
  const sessionStorageService: SessionStorageService =
    new SessionStorageService()
  axios.interceptors.request.use(
    (req) => {
      // Do something before request is sent
      const token = sessionStorageService.getData("token")
      if (!req.headers.Authorization) {
        req.headers.Authorization = token
      }
      return req
    },
    (err) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      // handle unauthorized requests - token expiration.
      if (
        err.response.status === 401 &&
        (err.response.data === "Unauthorized" ||
          err.response.data === "unauthorized")
      ) {
        // commented out because of testing reasons
        // sessionStorageService.clearAll()
        // window.location.reload()
        console.log("reloaded all")
      }
      throw err.response.data
    }
  )
}
