import React from "react";
import { Box } from "@material-ui/core";
import { processedDocuments } from "../statics/processed-documents";
import { OnboardingState } from "../store/onboarding/initialState";
import { CompanyDetailsTabForm } from "../types/company-profile";
import {
  CustomsDetailsTabForm,
  PermissionSuggestion,
} from "../types/custom-details";
import { ProcessedDocumentsTabForm } from "../types/processed-documents";
import { generatePath } from "react-router-dom";
import { history } from "react-router-guard";

export const deleteEmptyProp = (obj: any = {}) => {
  const object = { ...obj };
  for (const key in object) {
    const element = object[key];
    if (Array.isArray(element) && element.length === 0) {
      delete obj[key];
    } else if (typeof element === "object" && !Array.isArray(element)) {
      const newElement = deleteEmptyProp({ ...element });
      obj[key] = newElement;
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    } else if (!element) {
      delete obj[key];
    }
  }
  return obj;
};

export const checkAPIMapper = (
  onboardingObject: any,
  currentOnboardingData: OnboardingState
): [
  CompanyDetailsTabForm,
  CustomsDetailsTabForm,
  ProcessedDocumentsTabForm
] => {
  const [stepTwo, stepThree, stepFour] = [
    { ...currentOnboardingData.stepTwo },
    { ...currentOnboardingData.stepThree },
    { ...currentOnboardingData.stepFour },
    { ...currentOnboardingData.stepFive },
  ];
  //mapping for Step Two
  for (const key in stepTwo) {
    if (onboardingObject[key]) {
      stepTwo[key as keyof CompanyDetailsTabForm] = onboardingObject[key];
    }
  }
  //mapping for Step Three
  if (onboardingObject.permissions) {
    stepThree.permissionSuggestions = onboardingObject.permissions.map(
      (permission: PermissionSuggestion) => {
        return {
          key: permission.key,
          label: permission.label,
          active: permission.active,
          value: permission.value,
        };
      }
    );
  }
  if (onboardingObject.documents) {
    //populate with the static data
    stepFour.processedDocuments = [...processedDocuments.processedDocuments];

    onboardingObject.documents.forEach((document: string) => {
      const documentLabel = document.toLowerCase();
      const indexOfDocument = stepFour.processedDocuments.findIndex(
        (processedDocument) => processedDocument.label === documentLabel
      );
      if (indexOfDocument > -1) {
        stepFour.processedDocuments[indexOfDocument] = {
          ...stepFour.processedDocuments[indexOfDocument],
          active: true,
        };
      } else {
        // DocumentType = Others
        const othersIndex = processedDocuments.processedDocuments.length - 1;
        stepFour.processedDocuments[othersIndex] = {
          ...stepFour.processedDocuments[othersIndex],
          active: true,
          value: [
            ...(stepFour.processedDocuments[othersIndex].value as string[]),
            document,
          ],
        };
      }
    });
  }

  return [stepTwo, stepThree, stepFour];
};

export const getCustomerIdAndProjectIdFromURL = (
  defaultCustomerId: string,
  defaultProjectId: string,
  path: string[]
) => {
  let customerid = defaultCustomerId;
  let projectid = defaultProjectId;
  for (let i = 0; i < path.length; i++) {
    if (path[i] === "customer" && i < path.length - 1) {
      customerid = path[i + 1];
    } else if (path[i] === "project" && i < path.length - 1) {
      projectid = path[i + 1];
    }
  }
  console.log("projectid1",projectid)
  
  return [customerid, projectid];
};

export const handleBack = (
  appRoute: string,
  onboardingState: {
    customerId: string;
    projects: any;
    currentProjectIndex: number;
  },
  otherParams?: any
) => {
  const { customerId, projects, currentProjectIndex } = onboardingState;
  const lang = history.location.pathname.split("/")[1];
  // setting to store for furthur page
  const pathname =
    projects.length && customerId
      ? generatePath(appRoute, {
          lang,
          customerid: customerId,
          projectid:
            projects.length !== 0
              ? projects[currentProjectIndex]?.projectId
              : "default",
          ...otherParams,
        })
      : "";
  history.push({
    pathname,
  });
};

export function guidGenerator() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

export const checkIfObjectHasKey = (key: string, object: any) => {
  if (object && key in object) {
    return object[key];
  }
  return false;
};

export const ErrorComponent = ({ message }: { message: string }) => <div style={{ color: "#FF0047", fontSize: "0.6925rem" }}> {message}</div>;


export const toolTipTemplate = (heading: string, content: string) => (
  <Box>
    <b>{ heading }</b>
    <br />
    <Box>{ content }</Box>
  </Box>
);
