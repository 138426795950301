import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "./initialState";

export const dataExtractionSlice = createSlice({
  name: "dataextraction",
  initialState,
  reducers: {
    setDocUpdated: (state, { payload }: PayloadAction<boolean>) => {
      state.isDocUpdated = payload;
    },
    setSeletedQueue: (state, { payload }: PayloadAction<any>) => {
      state.seletedQueue = payload;
    },
    setPageNumbers: (state, { payload }: PayloadAction<any>) => {
      state.pageNumbers = payload;
    },
    setNumPage: (state, { payload }: PayloadAction<any>) => {
      state.numberOfPages = payload;
    },
    setSelectedDocuments: (state, { payload }: PayloadAction<any>) => {
      state.selectedDocuments = {
        ...state.selectedDocuments,
        ...payload,
      };
    },
    setDexterInputAccuracy: (state, { payload }: PayloadAction<any>) => {
      state.dexterInputAccurary = payload;
    },
    setDexterFilledOut: (state, { payload }: PayloadAction<any>) => {
      state.dexterFilledOut = payload;
    },
    setDexterInputFields: (state, { payload }: PayloadAction<any>) => {
      state.dexterInputFields = payload;
    },
    setDexterLineConnector: (state, { payload }: PayloadAction<any>) => {
      state.dexterLineConnector = payload;
    },
    setDexterScrollIntoView: (state, { payload }: PayloadAction<any>) => {
      state.dexterScrollIntoView = payload;
    },
    setUpdateQueueList: (state, { payload }: PayloadAction<boolean>) => {
      state.isQueueListUpdated = payload;
    },
  },
});

export const {
  setDocUpdated,
  setSeletedQueue,
  setPageNumbers,
  setNumPage,
  setSelectedDocuments,
  setDexterInputAccuracy,
  setDexterFilledOut,
  setDexterInputFields,
  setDexterLineConnector,
  setDexterScrollIntoView,
  setUpdateQueueList,
} = dataExtractionSlice.actions;

export default dataExtractionSlice.reducer;
