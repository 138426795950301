import { CancelTokenSource } from "axios";
import { CaseModel } from "digicust_types";
export interface UploadDialogProps {
  open: boolean;
  caseId: string;
  onClose: () => void;
  updateCaseItemOnUpload?: (updatedCaseItem: CaseModel) => void;
}
export enum UploadDialogTypes {
  NEW_CASE = "NEW_CASE",
  EXISTING_CASE = "EXISTING_CASE",
}
export interface FilesDropZoneProps {
  isDisabled: boolean;
  setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}
export interface ModuleInfo {
  moduleType?: string;
  moduleCategory?: string;
  queueId?: string;
}
export interface UploadFile extends File {
  cancelTokenSource?: CancelTokenSource & { canceled: boolean };
  uploadDate?: Date;
  classification?: string;
  moduleType?: string;
  moduleCategory?: string;
  queueId?: string;
}
