// const msalConfig = {
//   auth: {
//     clientId: "8a05a122-aa44-4ca3-ab31-078cf6b73748",
//     authority:
//       "https://login.microsoftonline.com/bf2e212e-cf03-4606-beaf-7715d6f28009",
//     redirectUri: `${window.origin}/`,
//   },
//   cache: {
//     cacheLocation: "sessionStorage", // This configures where your cache will be stored
//     storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//   },
// }

const msalB2Cconfig = {
  auth: {
    clientId: "c41cebcc-2a6c-4e60-a7d4-3e37c4f19ffb",
    authority:
      "https://digicustcustomers.b2clogin.com/tfp/digicustcustomers.onmicrosoft.com/B2C_1_digicust_signup/",
    knownAuthorities: ["digicustcustomers.b2clogin.com"],
    redirectUri: `${window.origin}/`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const APIURL = "https://dev.api.digicust.com";

export const productionEnvironmentConfig = {
  msalConfig: msalB2Cconfig,
  serverUrl: `${APIURL}/generic/api/`,
  scope: "api://428283fe-ae96-4dbb-a76a-03b80a6ab46f/CustomersDelegated",
};

/*  Dexter API */
export const prodDexterConfig = {
  serverUrl: `${APIURL}/dexter/api/`,
  msalConfig: msalB2Cconfig,
  scope: "api://428283fe-ae96-4dbb-a76a-03b80a6ab46f/CustomersDelegated",
};
/*  Data Extraction API */
export const prodDataExtractionConfig = {
  serverUrl: `${APIURL}/data-extraction/api/`,
  msalConfig: msalB2Cconfig,
  scope: "api://428283fe-ae96-4dbb-a76a-03b80a6ab46f/CustomersDelegated",
};

export const prodDexterDataExtractionConfig = {
  serverUrl: `${APIURL}/data-extraction/api/`,
  msalConfig: msalB2Cconfig,
  scope: "api://428283fe-ae96-4dbb-a76a-03b80a6ab46f/CustomersDelegated",
};
export const prodDocumentSplittingConfig = {
  serverUrl: `${APIURL}/document-splitting/api/`,
  msalConfig: msalB2Cconfig,
  scope: "api://428283fe-ae96-4dbb-a76a-03b80a6ab46f/CustomersDelegated",
};
