import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import onboardingSlice from "./onboarding";
import dashboardSlice from "./dashboard";
import dataExtractionSlice from "./dataExtraction";
import transitSlice from "./transit";
import importSlice from "./import";
import dataClassificationSlice from "./dataClassification";

const store = configureStore({
  reducer: {
    onboarding: onboardingSlice,
    dashboard: dashboardSlice,
    dataextraction: dataExtractionSlice,
    dataclassification: dataClassificationSlice,
    transit: transitSlice,
    import: importSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
