import { usesOfSoftware } from '../../statics/company-details';
import { countries } from '../../statics/countries';
import { processedDocuments } from '../../statics/processed-documents';
import { AdminProfileTabForm } from '../../types/admin-profile';
import { CompanyDetailsTabForm } from '../../types/company-profile';
import { CustomsDetailsTabForm } from '../../types/custom-details';
import { Project } from '../../types/dashboard';
import { ProcessedDocumentsTabForm } from '../../types/processed-documents';
import { Roles, TeamMembersTabForm } from '../../types/team-members';

export interface OnboardingState {
  currentProjectIndex: number;
  projects: Project[];
  projectId: string;
  stepNumber: number;
  customerId: string;
  stepOne: AdminProfileTabForm;
  stepTwo: CompanyDetailsTabForm;
  stepThree: CustomsDetailsTabForm;
  stepFour: ProcessedDocumentsTabForm;
  stepFive: TeamMembersTabForm;
}

// Define the initial state using that type
export const initialState: OnboardingState = {
  currentProjectIndex: 0,
  projects: [],
  projectId: '',
  stepNumber: 0,
  customerId: '',
  stepOne: {
    firstName: '',
    lastName: '',
    email: '',
    subscribeToNewsletter: false,
    acceptTerms: false,
  },
  stepTwo: {
    name: '',
    country: countries[11].code,
    companyAddress: '',
    city: '',
    zipCode: '',
    commercialRegistryEntry: '',
    vatNumber: '',
    eoriNumber: '',
    taxNumber: '',
    contactPerson: {
      name: '',
      email: '',
      address: '',
      phone: '',
    },

    inboundContactPerson: {
      name: '',
      email: '',
    },
    outboundContactPerson: {
      name: '',
      email: '',
    },

    typeOfCompany: '',
    typeOfIndustry: '',
    companySize: '',
    numberOfCustomers: '',

    useOfSoftware: usesOfSoftware[0].value,

    importsPerYear: '',
    exportsPerYear: '',
    specialProcedures: [],
    specialProceduresDeclarationPerYear: '',
    openingHoursInboundDepartment: [],
    openingHoursOutboundDepartment: [],
  },
  stepThree: {
    permissionSuggestions: [],
  },
  stepFour: {
    ...processedDocuments,
  },
  stepFive: {
    teamMembers: [{ email: '', role: Roles.None }],
  },
};
