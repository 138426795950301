import React from "react";
import {
  makeStyles,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#046bb2",
  },
}));

export default function SimpleBackdrop({isOpen}: { isOpen: boolean}) {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={isOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
