import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminProfileTabForm } from '../../types/admin-profile';
import { CompanyDetailsTabForm } from '../../types/company-profile';
import { CustomsDetailsTabForm } from '../../types/custom-details';
import { Project } from '../../types/dashboard';
import { ProcessedDocumentsTabForm } from '../../types/processed-documents';
import { TeamMembersTabForm } from '../../types/team-members';
import { initialState } from './initialState';

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setStepNumber: (state, { payload }: PayloadAction<number>) => {
      state.stepNumber = payload;
    },
    setStepOne: (state, { payload }: PayloadAction<AdminProfileTabForm>) => {
      state.stepOne = { ...payload };
    },
    setStepTwo: (state, { payload }: PayloadAction<CompanyDetailsTabForm>) => {
      state.stepTwo = { ...payload };
    },
    setStepThree: (
      state,
      { payload }: PayloadAction<CustomsDetailsTabForm>,
    ) => {
      state.stepThree = { ...payload };
    },
    setCustomerId: (state, { payload }: PayloadAction<string>) => {
      state.customerId = payload;
    },
    setProjectId: (state, { payload }: PayloadAction<string>) => {
      state.projectId = payload;
    },
    setStepFour: (
      state,
      { payload }: PayloadAction<ProcessedDocumentsTabForm>,
    ) => {
      state.stepFour = { ...payload };
    },
    setStepFive: (state, { payload }: PayloadAction<TeamMembersTabForm>) => {
      state.stepFive = { ...payload };
    },
    setProjects: (state, { payload }: PayloadAction<Project[]>) => {
      state.projects = [...payload];
    },
    setCurrentProjectIndex: (state, { payload }: PayloadAction<number>) => {
      state.currentProjectIndex = payload;
    },
  },
});

export const {
  setStepNumber,
  setStepOne,
  setStepTwo,
  setStepThree,
  setCustomerId,
  setProjectId,
  setStepFour,
  setStepFive,
  setProjects,
  setCurrentProjectIndex,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
