// import { RequireCaseItemDetailsParams } from '../../types/case-item-details';
import { DashboardService } from "../../services/onBoarding/dashboard.service";

const requireAnnotationCaseDetails = ({ match: { params } }: any) => {
  return new Promise((resolve, reject) => {
    try {
      const dashboardService = new DashboardService(
        params.customerid,
        params.projectid
      );
      const data = dashboardService.getAnnotationCaseMapping(params.caseId);
      resolve(data);
    } catch (error) {
      return reject();
    }
  });
};

export default requireAnnotationCaseDetails;
