import {
  DocumentTypes,
  ProcessedDocumentsTabForm,
} from '../types/processed-documents';

export const processedDocuments: ProcessedDocumentsTabForm = {
  processedDocuments: [
    {
      label: DocumentTypes.WAYBILL,
      active: false,
      value: 'Waybill',
    },
    {
      label: DocumentTypes.INVOICE,
      active: false,
      value: 'Invoice',
    },
    {
      label: DocumentTypes.DELIVERY_NOTE,
      active: false,
      value: 'Delivery Note',
    },
    {
      label: DocumentTypes.ORDER_CONFIRMATION,
      active: false,
      value: 'Order Confirmation',
    },
    {
      label: DocumentTypes.PACKING_LIST,
      active: false,
      value: 'Packing List',
    },
    {
      label: DocumentTypes.PREFERENCE_DOCUMENT,
      active: false,
      value: 'Preference Document',
    },
    {
      label: DocumentTypes.RECORD_OF_PAYMENT,
      active: false,
      value: 'Record of Payment',
    },
    {
      label: DocumentTypes.OTHERS,
      active: false,
      value: [],
    },
  ],
};
