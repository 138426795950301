import { SessionStorageService } from '../../services/session-storage/session-storage';
import { history } from 'react-router-guard';
const sessionStorageService: SessionStorageService = new SessionStorageService();

const requireLogin = () => {
  return new Promise((resolve, reject) => {
    const path: string[] = history.location.pathname.split('/');
    const lang = path[1];
    try {
      if (sessionStorageService.getData('token')) {
        resolve(sessionStorageService.getData('token'));
      } else {
        history.push({ pathname: `/${lang}/login` });
        return reject();
      }
    } catch (error) {
      history.push({ pathname: `/${lang}/login` });
      return reject();
    }
  });
};

export default requireLogin;
