import {
  stagingEnvironmentConfig,
  stagingDexterConfig,
  APIURL as APIURLSTG,
  stagingDataExtractionConfig,
  stagingDexterDataExtractionConfig,
  stagingDocumentSplittingConfig,
} from "./environment.staging";
import {
  productionEnvironmentConfig,
  prodDexterConfig,
  APIURL as APIURLPROD,
  prodDataExtractionConfig,
  prodDexterDataExtractionConfig,
  prodDocumentSplittingConfig,
} from "./environment.live";

process.version = "V0.0.1";
const stage = process.env.REACT_APP_STAGE;
export const config =
  stage === "staging" ? stagingEnvironmentConfig : productionEnvironmentConfig;

export const dexterConfig =
  stage === "staging" ? stagingDexterConfig : prodDexterConfig;
export const dataExtractionConfig =
  stage === "staging" ? stagingDataExtractionConfig : prodDataExtractionConfig;
export const dexterDataExtractionConfig =
  stage === "staging"
    ? stagingDexterDataExtractionConfig
    : prodDexterDataExtractionConfig;
export const documentSplittingConfig =
  stage === "staging"
    ? stagingDocumentSplittingConfig
    : prodDocumentSplittingConfig;

export const SocketURL = stage === "staging" ? APIURLSTG : APIURLPROD;

export const API_URL = "https://dev.api.digicust.com/generic/api";
export const FRONTEND_URL = "https://dev.app.digicust.com";
