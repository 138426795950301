import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "./initialState";

export const transitSlice = createSlice({
    name: "transit",
    initialState,
    reducers: {
        setShipper: (state, { payload }: PayloadAction<any>) => {
            state.shipper = payload
        },
        setConsignee: (state, { payload }: PayloadAction<any>) => {
            state.consignee = payload

        },
        setShipmentInformation: (state, { payload }: PayloadAction<any>) => {
            // console.log(payload, 'payload')
            state.shipmentInformation = payload
        },
        setObligatedParty: (state, { payload }: PayloadAction<any>) => {
            state.obligatedParty = payload

        },
        setPreliminaryDocuments: (state, { payload }: PayloadAction<any>) => {
            // console.log(payload, 'payload { ...info, id: permanentId, length: 104 } ')

            state.preliminaryDocuments = payload

        },
        setFreightDetails: (state, { payload }: PayloadAction<any>) => {
            state.freightDetails = payload

        },
        setTransitItems: (state, { payload }: PayloadAction<any>) => {
            state.transitItems = payload
        },
        pushTransitItems: (state, { payload }: PayloadAction<any>) => {
            const states = state?.transitItems?.data[0];
            console.log("payload", payload, states, 'items')
            // states.items[0].splice(payload?.index, 1, payload?.row)
            state.transitItems.data[0] = payload.row
        },
        setSidebar: (state, { payload }: PayloadAction<any>) => {
            console.log(payload, 'payload { ...info, id: permanentId, length: 104 } ')
            state.sidebar = payload
        },

        setId: (state, { payload }: PayloadAction<any>) => {
            state.id = payload
        },
        setIds: (state, { payload }: PayloadAction<any>) => {
            const ids = state.ids
            payload.forEach((item: any) => ids.push(item))
            const uniqueIds = ids.filter((c: any, index: any) => ids.indexOf(c) === index);
            state.ids = uniqueIds
        },
        setAllIds: (state, { payload }: PayloadAction<any>) => {
            const ids = state.allIds
            payload.forEach((item: any) => ids.push(item))
            const uniqueIds = ids.filter((c: any, index: any) => ids.indexOf(c) === index);
            state.allIds = uniqueIds
        },
        pushIds: (state, { payload }: PayloadAction<any>) => {

            const ids = state.allIds
            const start = ids.findIndex((ele: any) => ele === payload.id)
            ids.splice(start + 1, 0, ...payload.childIds)
            const uniqueIds = ids.filter((c: any, index: any) => ids.indexOf(c) === index);
            state.allIds = uniqueIds
        },
        setInputIds: (state, { payload }: PayloadAction<any>) => {
            state.inputIds = []
            state.inputIds = payload
        },
        setSavingArray: (state, { payload }: PayloadAction<any>) => {
            const states = state.savingArray
            console.log(states, 'xyz payload')
            if (states.length > 0) {
                states.map((ele: { path: any; value: any; }) => {
                    if (ele.path === payload.path) {
                        ele.value = payload.value
                    } else {
                        states.push(payload)
                    }
                    return 1;
                })
                const unique = states.filter((v: { path: any; }, i: any, a: any[]) => a.findIndex((t: any) => (t.path === v.path)) === i)
                state.savingArray = unique
            } else {
                states.push(payload)
                state.savingArray = states
            }
        },
        resetSavingArray: (state, { payload }: PayloadAction<any>) => {
            state.savingArray = payload
        },


    },
});

export const {
    setShipper,
    setConsignee,
    setShipmentInformation,
    setObligatedParty,
    setPreliminaryDocuments,
    setFreightDetails,
    setTransitItems,
    pushTransitItems,
    setSidebar,
    setId,
    setIds,
    setAllIds,
    pushIds,
    setInputIds,
    resetSavingArray,
    setSavingArray
} = transitSlice.actions;

export default transitSlice.reducer;