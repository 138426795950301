import {
  requireLanguage,
  requireLogin,
  requireLogout,
  requireOnboarding,
} from "./guards";
import { lazy } from "react-router-guard";
import requireCaseItemDetails from "./guards/requireCaseItemDetails";
import requireAnnotationCaseDetails from "./guards/requireAnnotationCaseDetails";

export enum AppRoute {
  Login = "/:lang/login",
  SignUp = "/:lang/signup",
  Onboarding = "/:lang/onboarding",
  materialMaster = "/:lang/customer/:customerid/project/:projectid/materialMaster",
  stakeholderMaster = "/:lang/customer/:customerid/project/:projectid/stakeholderMaster",
  DashboardLanding = "/:lang/customer/:customerid/project/:projectid",
  ImportCases = "/:lang/customer/:customerid/project/:projectid/module/import",
  TransitProcedure = "/:lang/customer/:customerid/project/:projectid/module/TransitProcedure",
  TransitProcedureDetails = "/:lang/customer/:customerid/project/:projectid/module/cases/:caseId/TransitProcedureDetails",
  ExportCases = "/:lang/customer/:customerid/project/:projectid/module/export",
  CustomInvoice = "/:lang/customer/:customerid/project/:projectid/module/custominvoice",
  DataExtraction = "/:lang/customer/:customerid/project/:projectid/module/dataextraction/:queueid/:title",
  CaseItemDetails = "/:lang/customer/:customerid/project/:projectid/module/cases/:caseId",
  ExportCaseItemDetails = "/:lang/customer/:customerid/project/:projectid/module/cases/:caseId",
  ExecutionStrategies = "/:lang/customer/:customerid/project/:projectid/execution-strategies",
  ExecutionDetails = "/:lang/customer/:customerid/project/:projectid/module/exec/:exceId",
  AddExecution = "/:lang/customer/:customerid/project/:projectid/module/exec/add",
  DataExtractionList = "/:lang/customer/:customerid/project/:projectid/data-extraction-queues",
  AddExtractionQueue = "/:lang/customer/:customerid/project/:projectid/module/queue/add",
  EditExtractionQueue = "/:lang/customer/:customerid/project/:projectid/module/queue/:queueId",
  ModuleSettings = "/:lang/customer/:customerid/project/:projectid/module/settings",
  AnnotationCaseDetails = "/:lang/customer/:customerid/project/:projectid/module/annotation/cases/:caseId",
  DataClassification = "/:lang/customer/:customerid/project/:projectid/module/dataclassification/:queueid",
  SearchPage = "/:lang/customer/:customerid/project/:projectid/module/search/:value",
  DataClassificationDetails= "/:lang/customer/:customerid/project/:projectid/module/dataclassificationdetails/:caseId",
}
// eslint-disable-next-line import/no-anonymous-default-export
export default () => [
  {
    path: "/:lang",
    canActivate: [requireLanguage],
    routes: [
      {
        path: AppRoute.DashboardLanding,
        component: lazy(() => import("../layouts/Dashboard/Dashboard")),
        canActivate: [requireLogin, requireOnboarding],
        meta: {
          title: "Dashboard",
        },
        routes: [
          {
            path: AppRoute.DashboardLanding,

            exact: true,
            component: lazy(
              () => import("../components/DashboardLanding/DashboardLanding")
            ),
            meta: {
              title: "Dashboard",
            },
          },
          {
            path: AppRoute.ImportCases,

            exact: true,
            component: lazy(
              () => import("../components/ImportCases/ImportCases")
            ),
            meta: {
              title: "Import Cases",
            },
          },
          {
            path: AppRoute.ExportCases,
            exact: true,
            component: lazy(
              () => import("../components/ExportCases/ExportCases")
            ),
            meta: {
              title: "Export Cases",
            },
          },
          {
            path: AppRoute.TransitProcedureDetails,
            exact: true,
            component: lazy(
              () =>
                import("../components/CaseItemDetails/TransitCaseItemDetails")
            ),
            canActivate: [requireCaseItemDetails],

            meta: {
              title: "Transit Procedure",
            },
          },
          {
            path: AppRoute.DataClassificationDetails,
            exact: true,
            component: lazy(
              () => 
                import(
                  "../components/DataClassificationDetails/DataClassificationDetails"
                )
            ),
            meta: {
              title: "Document Classification Details"
            },
          },
          {
            path: AppRoute.TransitProcedure,
            exact: true,
            component: lazy(
              () => import("../components/TransitProcedure/TransitProcedure")
            ),
            canActivate: [requireCaseItemDetails],

            meta: {
              title: "Transit Procedure",
            },
          },
          {
            path: AppRoute.CaseItemDetails,

            exact: true,
            component: lazy(
              () => import("../components/CaseItemDetails/CaseItemDetails")
            ),
            canActivate: [requireCaseItemDetails],
            meta: {
              title: "Case Item Details",
            },
          },
          {
            path: AppRoute.ExportCaseItemDetails,

            exact: true,
            component: lazy(
              () => import("../components/CaseItemDetails/ExportCaseItemDetails")
            ),
            canActivate: [requireCaseItemDetails],
            meta: {
              title: "Case Item Details",
            },
          },
          {
            path: AppRoute.AnnotationCaseDetails,

            exact: true,
            component: lazy(
              () =>
                import(
                  "../components/AnnotationCaseItemDetails/AnnotationCaseItemDetails"
                )
            ),
            canActivate: [requireAnnotationCaseDetails],
            meta: {
              title: "Annotation Case Details",
            },
          },
          {
            path: AppRoute.ExecutionStrategies,
            exact: true,
            component: lazy(
              () =>
                import("../components/ExecutionStrategies/ExecutionStrategies")
            ),
            meta: {
              title: "Execution Strategies",
            },
          },
          {
            path: AppRoute.AddExecution,
            exact: true,
            component: lazy(
              () => import("../components/ExecutionStrategies/ExecutionDetails")
            ),
            meta: {
              title: "Add Execution Strategies",
            },
          },
          {
            path: AppRoute.ExecutionDetails,
            exact: true,
            component: lazy(
              () => import("../components/ExecutionStrategies/ExecutionDetails")
            ),
            meta: {
              title: "Execution Strategies Details",
            },
          },
          {
            path: AppRoute.DataExtractionList,
            exact: true,
            component: lazy(
              () => import("../components/DataExtraction/QueuesList")
            ),
            meta: {
              title: "Data Extraction queue List",
            },
          },
          {
            path: AppRoute.AddExtractionQueue,
            exact: true,
            component: lazy(
              () => import("../components/DataExtraction/QueueDetails")
            ),
            meta: {
              title: "Add Data Extraction Queue",
            },
          },
          {
            path: AppRoute.EditExtractionQueue,
            exact: true,
            component: lazy(
              () => import("../components/DataExtraction/QueueDetails")
            ),
            meta: {
              title: "Edit Data Extraction Queue",
            },
          },
          {
            path: AppRoute.ModuleSettings,
            exact: true,
            component: lazy(() => import("../components/Modules/Settings")),
            meta: {
              title: "Module Settings",
            },
          },
          {
            path: AppRoute.DataExtraction,
            exact: true,
            component: lazy(
              () => import("../components/DataExtraction/DataExtraction")
            ),
            meta: {
              title: "Data Extraction",
            },
          },
          {
            path: AppRoute.DataClassification,
            exact: true,
            component: lazy(
              () =>
                import(
                  "../components/DataClassificationModule/DataClassification/DataClassification"
                )
            ),
            meta: {
              title: "Document Classification",
            },
          },
          {
            path: AppRoute.SearchPage,
            exact: true,
            component: lazy(
              () => import("../components/SearchModule/SearchPage/SearchPage")
            ),
            meta: {
              title: "Search",
            },
          },
          {
            path: AppRoute.materialMaster,

            exact: true,
            component: lazy(
              () => import("../components/materialMaster/materialMaster")
            ),
            canActivate: [requireCaseItemDetails],
            meta: {
              title: "Material Master",
            },
          },
          {
            path: AppRoute.stakeholderMaster,

            exact: true,
            component: lazy(
              () => import("../components/stakeholderMaster/stakeholderMaster")
            ),
            canActivate: [requireCaseItemDetails],
            meta: {
              title: "Stakeholder Master",
            },
          },
          {
            path: `${AppRoute.DashboardLanding}/*`,
            redirect: AppRoute.DashboardLanding,
          },
        ],
      },
      {
        path: AppRoute.Onboarding,
        exact: true,
        component: lazy(() => import("../layouts/Onboarding/Onboarding")),
        canActivate: [requireLogin, requireOnboarding],
        meta: {
          title: "Digicust",
        },
      },
      {
        // signup and signin should route to the same
        path: AppRoute.Login,
        exact: true,
        component: lazy(() => import("../layouts/SignIn/SignIn")),
        canActivate: [requireLogout],
        meta: {
          title: "Login",
        },
      },
      {
        // signup and signin should route to the same
        path: AppRoute.SignUp,
        exact: true,
        component: lazy(() => import("../layouts/SignIn/SignIn")),
        meta: {
          title: "Sign up",
        },
      },

      // {
      //   path: '*',
      //   component: Onboarding,
      //   ignoreGlobal: true,
      // },
    ],
  },
  {
    path: "/",
    canActivate: [requireLanguage],
  },
];
