import { createMuiTheme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface Fonts {
  h1?: CSSProperties;
  h2?: CSSProperties;
  h3?: CSSProperties;
  h4?: CSSProperties;
  h5?: CSSProperties;
  h6?: CSSProperties;
  body1?: CSSProperties;
  body2?: CSSProperties;
  button?: CSSProperties;
  subtitle1?: CSSProperties;
  subtitle2?: CSSProperties;
  caption?: CSSProperties;
  overline?: CSSProperties;
}

const fonts: Fonts = {
  h1: {
    // 40px
    fontSize: "2.5rem",
  },
  h2: {
    // 30px
    fontSize: "1.875rem",
    fontWeight: 400,
  },
  h3: {
    // 24px
    fontSize: "1.5rem",
    // fontWeight: 500,
    fontWeight: 400,
  },
  h4: {
    // 20px
    fontSize: "1.25rem",
  },
  subtitle1: {
    // 18px
    fontSize: "1.125rem",
  },
  subtitle2: {
    // 16px
    fontSize: "1rem",
    fontWeight: "unset",
  },
  button: {
    // 16px
    fontSize: "1rem",
    fontWeight: 500,
  },
  h5: {
    // 14px
    fontSize: "0.875rem",
    fontWeight: "lighter",
  },

  h6: {
    // 14px
    fontSize: "0.875rem",
    fontWeight: 500,
  },
  body1: {
    // 13px
    fontSize: "0.8125rem",
  },
  body2: {
    // 11px
    fontSize: "0.6875rem",
  },
  caption: {
    // 10px
    fontSize: "0.625rem",
    fontWeight: 400,
  },
  overline: {
    // 9px
    fontSize: "0.5625rem",
  },
};

const {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  body1,
  body2,
  caption,
  button,
  subtitle1,
  subtitle2,
  overline,
} = fonts;

const defaultTheme = createMuiTheme();

export const theme = createMuiTheme({
  // colors
  // card title  - #5A5C4F

  // #20375c dark blue
  palette: {
    primary: { 
      main: "#046BB2",
     contrastText: "#fff"
    //  contrastText: "#5A5C4F"
     },
    secondary: { main: "#FEA305", contrastText: "#FFFFFF" },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    body1,
    body2,
    subtitle1,
    subtitle2,
    caption,
    overline,
    button: {
      ...button,
      textTransform: "unset",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 1440,
      md: 1920,
      lg: 2048,
      xl: 3840,
    },
  },
  overrides: {
    MuiCircularProgress: {
      colorSecondary: {
        color: "#d8d8d8",
      },
    },

    MuiDialog: {
      paperWidthSm: {
        borderRadius: "8px !important",
        border: "1px solid rgb(151, 151, 151)",
        padding: "25px",
        width: "855px !important",
      },
      paperWidthXs: {
        borderRadius: "8px !important",
        border: "1px solid rgb(151, 151, 151)",
        padding: "25px",
        width: "468px !important",
      },
    },
    MuiChip: {
      label: {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
      deleteIconColorPrimary: {
        color: "#ffffff",
      },
    },
    MuiDialogTitle: {
      root: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        paddingBottom: "unset !important",
        paddingRight: "8px",
        color: "#515151",
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: "unset !important",
        paddingRight: "8px",
      },
    },
    MuiStepConnector: {
      lineVertical: {
        minHeight: "45px",
      },
      vertical: {
        padding: "unset",
        marginLeft: "1rem",
        marginTop: "5px",
        marginBottom: "5px",
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: "unset",
        padding: "unset",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "#fea305",
          background: "#fff8eb",
        },
      },
    },

    MuiButton: {
      root: {
        borderRadius: "3px !important",
      },
      textSizeSmall: {
        padding: "unset",
      },
      text: {
        padding: "5px 10px",
      },
      containedPrimary: {
        color: "#ffffff",
        "&$disabled": {
          backgroundColor: "#046bb2",
          color: "#ffffff",
          opacity: "0.38",
        },
      },
      textSecondary: {
        color: "#b9b9b9",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#b9b9b9",
        fontSize: "1rem",
      },
    },
    MuiInputLabel: {
      shrink: {
        color: "#9a9a9a",
        fontWeight: 400,
        transform: "translate(12px, 7px) scale(0.625) !important",
      },
      root: {
        fontWeight: 300,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "0.5625rem",
        fontWeight: 500,
        color: "#b9b9b9",
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          color: "#b9b9b9",
          fontWeight: 300,
          opacity: 1,
        },
      },
    },
    MuiFilledInput: {
      root: {
        fontSize: "1rem",
        background: "#f9f9f9 !important",
      },
      input: {
        "&:-webkit-autofill": {
          Transition: "color 9999s ease-out, background-color 9999s ease-out",
          WebkitTransition:
            "color 9999s ease-out, background-color 9999s ease-out",
          TransitionDelay: "9999s",
          WebkitTransitionDelay: "9999s",
        },
      },
      underline: {
        "&:before": {
          borderBottom: "1px solid #b9b9b9",
        },
        "&$disabled": {
          "&:before": {
            borderBottomStyle: "solid",
          },
        },
        "&:hover": {
          "&:before": {
            borderBottom: "1px solid #b9b9b9",
          },
        },
      },
    },

    MuiTypography: {
      colorPrimary: {
        color: "#046bb2",
      },
      colorSecondary: {
        color: "#808080",
      },
      colorTextPrimary: {
        "&.white": {
          color: "#ffffff",
        },
      },
      colorTextSecondary: {
        color: "#5a5c4f",
        // color: "#fff",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#ffffff",
      },
      middle: {
        marginLeft: "5px",
        marginRight: "5px",
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#24c0dc",
        borderRadius: "3px",
        boxShadow:
          "1px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(148, 172, 172, 0.3)",
        minHeight: "24px",
        fontSize: "10px",
        color: "white",
      },
      arrow: {
        color: "#24C0DC !important",
        "&:before": {
          border: "1px solid #24c0dc",
        },
      },
    },
    MuiAppBar: {
      root: {
        "&.top-nav": {
          height: "50px",
          boxShadow: "unset",
        },
      },
    },
    MuiToolbar: {
      root: {
        "&.top-nav-toolbar": {
          paddingLeft: "30px",
          paddingRight: "19px",
          minHeight: "100% !important",
          background: "rgb(249, 249, 249)",
          border: "1px solid rgb(238, 238, 238)",
        },
      },
    },
    MuiIconButton: {
      root: {
        "&.side-nav-button": {
          borderRadius: "7px",
          width: "44px",
          height: "44px",
          padding: "8px",
          "& svg": {
            fill: "white",
            width: "100%",
            height: "100%",
          },
          "&:hover": {
            backgroundColor: "#ffffff",
            "& svg": {
              fill: "#122a51",
            },
          },
          "&.active": {
            backgroundColor: "#ffffff",
            "& svg": {
              fill: "#122a51",
            },
          },
        },
        "&.side-nav-button-disable": {
          "& svg": {
            fill: "#9f9f9f !important",
            width: "100%",
            height: "100%",
          },
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        overflow: "hidden",
        borderRight: "unset",
      },
      root: {
        transition: defaultTheme.transitions.create("width", {
          easing: defaultTheme.transitions.easing.sharp,
          duration: defaultTheme.transitions.duration.enteringScreen,
        }),
        width: "60px",
      },
    },
    MuiAvatar: {
      root: {
        width: "35px",
        height: "35px",
      },
    },
    MuiLinearProgress: {
      colorPrimary: { backgroundColor: "#ffffff" },
      barColorPrimary: { backgroundColor: "#d3e8ec" },
    },
    MuiSvgIcon: {
      colorDisabled: {
        color: "#d8d8d8",
      },
      colorAction: {
        color: "#212529",
      },
      colorPrimary: {
        color: "#046bb2",
      },
      colorSecondary: {
        color: "#808080",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
      },
    },
  },
});
