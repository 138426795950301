import axios from 'axios';
import { config } from '../../config';
import { deleteEmptyProp } from '../../helpers';
import { AdminProfileTabForm } from '../../types/admin-profile';
import { CompanyDetailsTabForm } from '../../types/company-profile';
import { CustomsDetailsTabForm } from '../../types/custom-details';
import {
  DocumentTypes,
  ProcessedDocumentsTabForm,
} from '../../types/processed-documents';
import { TeamMembersTabForm } from '../../types/team-members';

export class OnBoardingService {
  public checkAPI = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + 'onboarding/check2');
      return response.data;
    } catch (error) {
      return error;
    }
  };
  public getProjects = async (id: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `/customers/${id}/projects`);
      return response.data;
    } catch (error) {
      return error;
    }
  };
  public createProfile = async (profile: AdminProfileTabForm): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + 'onboarding/create-profile',
        profile,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
  public createCompany = async (
    company: CompanyDetailsTabForm,
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + 'onboarding/create-company',
        deleteEmptyProp({ ...company }),
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
  public getIndustries = async () => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + 'lists/industries?lang=en');
      return response.data;
    } catch (error) {
      return error;
    }
  };
  public getTypesOfCompanies = async () => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(
        serverUrl + 'lists/types-of-company?lang=en',
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
  public addCustomDetails = async (
    customDetails: CustomsDetailsTabForm,
    customerId: string,
  ): Promise<any> => {
    try {
      const customDetailsObject = {
        customerId,
        permissions: customDetails.permissionSuggestions
          .filter((permission) => permission.value)
          .map((permission) => ({
            key: permission.key,
            value: permission.value,
            active: permission.active,
          })),
      };
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + 'onboarding/add-customs-details',
        customDetailsObject,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public addProcessedDocuments = async (
    documents: ProcessedDocumentsTabForm,
    customerId: string,
  ): Promise<any> => {
    try {
      const processedDocuments = documents.processedDocuments
        .filter((document) =>
          document.label === DocumentTypes.OTHERS
            ? document.value.length !== 0 && document.active
            : document.active,
        )
        .map((document) => {
          return document.value;
        })
        .flat();
      const processedDocumentObject = {
        customerId,
        processedDocuments,
      };
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + 'onboarding/add-processed-documents',
        processedDocumentObject,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
  public addTeam = async (
    team: TeamMembersTabForm,
    customerId: string,
  ): Promise<any> => {
    try {
      const teamMembers = team.teamMembers.filter(
        (member) => member.email !== '',
      );
      const teamMembersObject = {
        customerId,
        teamMembers,
      };
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + 'onboarding/add-team',
        teamMembersObject,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  };
}
