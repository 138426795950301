export interface transitState {

    // Waybill
    waybillSender: any;
    waybillRecipient: any;
    waybillShipmentInformation: any;
    waybillApplicant: any;
    waybillPreliminaryDocuments: any;
    waybillFreightDetails: any;

    // Invoices
    invoiceSender: any;
    invoiceGoodRecipient: any;
    invoiceRecipient: any;
    invoiceShipmentInformation: any;
    invoiceItems: any;

    // Cost Overview 
    costOverview: any;

    // Packaging List 
    packagingListSender: any;
    packagingListGoodRecipient: any;
    packagingListRecipient: any;
    packagingListShipmentInformation: any;
    packagingListItems: any;

    // right sidebar
    sidebar: any;
    ids: any,
}

export const initialState = {
    // Waybill
    waybillSender: null,
    waybillRecipient: null,
    waybillShipmentInformation: null,
    waybillApplicant: null,
    waybillPreliminaryDocuments: null,
    waybillFreightDetails: null,

    // Invoices
    invoiceSender: null,
    invoiceGoodRecipient: null,
    invoiceRecipient: null,
    invoiceShipmentInformation: null,
    invoiceItems: {
        data: [] as any
    },

    // Cost Overview 
    costOverview: null,

    // Packaging List 
    packagingListSender: null,
    packagingListGoodRecipient: null,
    packagingListRecipient: null,
    packagingListShipmentInformation: null,
    packagingListItems: null,

    sidebar: {
        id: null,
        data: null
    },
    ids: [] as any, // all left ids 
    allIds: [] as any, // all ids include both left and right(input) ids 
    id: null,  // selected id 
    savingArray: [] as any,
    inputIds: [] as any
}