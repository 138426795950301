import { RequireCaseItemDetailsParams } from '../../types/case-item-details';
import { DashboardService } from '../../services/onBoarding/dashboard.service';

const requireCaseItemDetails = ({
  match: { params },
}: RequireCaseItemDetailsParams) => {
  return new Promise((resolve, reject) => {
    try {
      const dashboardService = new DashboardService(
        params.customerid,
        params.projectid,
      );
      const data = dashboardService.getCaseMapping(params.caseId);
      resolve(data);
    } catch (error) {
      return reject();
    }
  });
};

export default requireCaseItemDetails;
