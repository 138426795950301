import React from "react"
import ReactDOM from "react-dom"
import "./i18n"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { setupInterceptors } from "./interceptors"
import { Provider } from "react-redux"
import store from "./store"

// @ts-expect-error there are no types
import authentication from "react-azure-b2c"

authentication.initialize({
  instance: "https://digicustcustomers.b2clogin.com/",
  tenant: "digicustcustomers.onmicrosoft.com",
  signInPolicy: "B2C_1_digicust_signup",
  clientId: "c41cebcc-2a6c-4e60-a7d4-3e37c4f19ffb",
  cacheLocation: "sessionStorage",
  scopes: ["https://digicustcustomers.onmicrosoft.com/api/user_impersonation"],
  redirectUri: "https://dev.app.digicust.com/en/login",
  postLogoutRedirectUri: window.location.origin,
})

// authentication.run(() => {
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
)
// })

// Http Interceptors
setupInterceptors()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
