export enum Roles {
  None = '',
  REPRESENTATIVE = 'representative',
  Supervisor = 'supervisor',
  IMPORT_SPECIALIST = 'import specialist',
  EXPORT_SPECIALIST = 'export specialist',
}
export interface TeamMember {
  email: string;
  role: Roles;
}
export interface TeamMembersTabForm {
  teamMembers: TeamMember[];
}
