import { config, dexterConfig } from "../../config";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  CaseModel,
  DigicustDocumentType,
  FilterCondition,
} from "digicust_types";
import { UploadFile } from "../../types/upload-dialog";
import { CaseItemDetailsGuardData } from "../../types/case-item-details";
import { GetExcecutionList, ExecItem } from "../../types/execution-strategies";
import { GetQueuesList, QueueListItem } from "../../types/data-queues";
import { ExecutionStrategy, DigicustQueueModel } from "digicust_types";

export interface GetCasesListOptions {
  continuationToken: string;
  filter: {
    field: string;
    condition: FilterCondition;
    value: string | undefined | boolean | Date | number;
  }[];
  sort?: { field: string; order: "ASC" | "DESC" }[];
  properties?: string[];
  search?: string[] | null[];
  page?: number;
  pageLimit?: number;
}
export class DashboardService {
  private projectId: string;
  private customerId: string;

  public constructor(customerId: string, projectId: string) {
    this.projectId = projectId;
    this.customerId = customerId;
    // this.projectId = window.location.href.split("/")[7];
    // this.customerId = window.location.href.split("/")[5];
  }
  public getCaseItem = async (
    caseId: string
  ): Promise<{
    result: CaseModel;
  }> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response = await axios.get(
          serverUrl + `${this.customerId}/${this.projectId}/cases/${caseId}`
        );
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public getFileOfaCase = async (
    caseId: string,
    originalName: string,
    fileName: string
  ): Promise<ArrayBuffer | string> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response = await axios.get(
          serverUrl +
            `${this.customerId}/${this.projectId}/cases/${caseId}/files/${originalName}/${fileName}`,
          {
            responseType: "arraybuffer",
          }
        );
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        return fileURL;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public getCasesList = async (
    options: GetCasesListOptions
  ): Promise<{
    result: CaseModel[];
    options: string[];
    hasMoreResults: boolean;
    continuationToken?: string;
  }> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response = await axios.get(
          serverUrl +
            `${this.customerId}/${
              this.projectId
            }/cases?options=${encodeURIComponent(JSON.stringify(options))}`
        );
        console.log(" LOKE getCasesList:  ", response.data);
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public deleteCase = async (id: string): Promise<{ result: string }> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response = await axios.delete(
          serverUrl + `${this.customerId}/${this.projectId}/cases/${id}`
        );
        console.log(" LOKE deleteCase:  ", response.data);
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public uploadFileToCaseItem = async (
    caseId: string,
    file: UploadFile,
    onUploadProgress: AxiosRequestConfig["onUploadProgress"]
  ): Promise<CaseModel> => {
    try {
      const { serverUrl } = config;
      const uploadUrl = serverUrl.replace("generic", "upload");

      const formData = new FormData();
      formData.append("file", file);
      if (file.classification) {
        formData.append("classification", file.classification);
      }
      if (this.customerId && this.projectId) {
        const response = await axios.post(
          uploadUrl + `${this.customerId}/${this.projectId}/${caseId}`,
          formData,
          {
            onUploadProgress,
            cancelToken: file.cancelTokenSource?.token,
          }
        );
        console.log(" LOKE uploadFileToCaseItem:  ", response.data);
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public uploadFileToNewCaseItem = async (
    file: UploadFile,
    onUploadProgress: AxiosRequestConfig["onUploadProgress"]
  ): Promise<CaseModel> => {
    try {
      const { serverUrl } = config;
      const uploadUrl = serverUrl.replace("generic", "upload");

      const formData = new FormData();
      formData.append("file", file);
      console.log("ARV UPLOADService: ", file.classification);
      if (file.classification?.toString().toLowerCase() === "invoice") {
        formData.append("classification", DigicustDocumentType.Invoice);
      } else if (file.classification?.toString().toLowerCase() === "waybill") {
        formData.append("classification", DigicustDocumentType.Waybill);
      } else if (
        file.classification?.toString().toLowerCase() === "packing list"
      ) {
        formData.append("classification", DigicustDocumentType.PackingList);
      } else if (
        file.classification?.toString().toLowerCase() === "order confirmation"
      ) {
        formData.append("classification", "order confirmation");
      } else if (
        file.classification?.toString().toLowerCase() === "delivery note"
      ) {
        formData.append("classification", "delivery note");
      }
      /*if (file.classification) {
        formData.append("classification", file.classification);
      }*/
      if (file.moduleType) {
        formData.append("moduleType", file.moduleType);
      }
      if (file.moduleCategory) {
        formData.append("moduleCategory", file.moduleCategory);
      }
      if (file.queueId) {
        formData.append("queueId", file.queueId);
      }
      if (this.customerId && this.projectId) {
        const response = await axios.post(
          uploadUrl + `${this.customerId}/${this.projectId}/new`,
          formData,
          {
            onUploadProgress,
            cancelToken: file.cancelTokenSource?.token,
          }
        );
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };

  public getCaseMapping = async (
    caseId: string
  ): Promise<CaseItemDetailsGuardData> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response: AxiosResponse<CaseItemDetailsGuardData> =
          await axios.get(
            serverUrl +
              `${this.customerId}/${this.projectId}/cases/${caseId}/mapping`
          );
        console.log(" LOKE getCaseMapping:  ", response.data);
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public getAnnotationCaseMapping = async (caseId: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response: AxiosResponse<any> = await axios.get(
          serverUrl +
            `${this.customerId}/${this.projectId}/cases/${caseId}/mapping`
        );
        console.log(" LOKE getCaseMapping:  ", response.data);
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public getExecutionStrategies = async (
    options: GetExcecutionList
  ): Promise<{
    result: ExecItem[];
    options: string[];
    hasMoreResults: boolean;
    continuationToken?: string;
  }> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response = await axios.get(
          serverUrl +
            `${this.customerId}/${
              this.projectId
            }/execution-strategies?options=${encodeURIComponent(
              JSON.stringify(options)
            )}`
        );
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public deleteExce = async (id: string): Promise<string> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response = await axios.delete(
          serverUrl +
            `${this.customerId}/${this.projectId}/execution-strategies/${id}`
        );
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public getExecItem = async (
    id: string
  ): Promise<{
    result: any;
  }> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response = await axios.get(
          serverUrl +
            `${this.customerId}/${this.projectId}/execution-strategies/${id}`
        );
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public saveExecution = async (
    formData: ExecutionStrategy,
    handleBack: () => void
  ): Promise<ExecutionStrategy> => {
    try {
      const { serverUrl } = config;

      if (formData.id && this.customerId && this.projectId) {
        const response = await axios.patch(
          serverUrl +
            `${this.customerId}/${this.projectId}/execution-strategies/${formData.id}`,
          formData
        );
        return response.data;
      }
      if (this.customerId && this.projectId) {
        const response = await axios.post(
          serverUrl +
            `${this.customerId}/${this.projectId}/execution-strategies`,
          formData
        );
        handleBack();
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public getDataQueues = async (
    options: GetQueuesList
  ): Promise<{
    result: QueueListItem[];
    options: string[];
    hasMoreResults: boolean;
    continuationToken?: string;
  }> => {
    try {
      const { serverUrl } = dexterConfig;
      if (this.customerId && this.projectId) {
        const response = await axios.get(
          serverUrl +
            `${this.customerId}/${
              this.projectId
            }/queues?options=${encodeURIComponent(JSON.stringify(options))}`
        );
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public getExtractionDefaultValue = async (): Promise<{
    data: any;
  }> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response = await axios.get(
          serverUrl + `lists/editStrategyDefaultValues`
        );
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public getSubscriptions = async (): Promise<{
    result: any[];
    options: string[];
    hasMoreResults: boolean;
    continuationToken?: string;
  }> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response = await axios.get(
          serverUrl + `${this.customerId}/${this.projectId}/subscriptions`
        );
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public deleteQueue = async (id: string): Promise<boolean> => {
    try {
      const { serverUrl } = dexterConfig;
      if (this.customerId && this.projectId) {
        const response = await axios.delete(
          serverUrl + `${this.customerId}/${this.projectId}/queues/${id}`
        );
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public saveQueue = async (
    formData: DigicustQueueModel,
    handleBack: () => void
  ): Promise<DigicustQueueModel> => {
    try {
      const { serverUrl } = dexterConfig;
      if (this.customerId && this.projectId) {
        const response = await axios.put(
          serverUrl + `${this.customerId}/${this.projectId}/queues`,
          formData
        );
        handleBack();
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public getQueueItem = async (
    id: string
  ): Promise<{
    result: any;
  }> => {
    try {
      const { serverUrl } = dexterConfig;
      if (this.customerId && this.projectId) {
        const response = await axios.get(
          serverUrl + `${this.customerId}/${this.projectId}/queues/${id}`
        );
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public getQueueDefaultValue = async (): Promise<{
    data: any;
  }> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response = await axios.get(serverUrl + `lists/queueCustomFields`);
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public getWorkspaceValues = async (): Promise<{
    data: any;
  }> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response = await axios.get(
          serverUrl +
            `${this.customerId}/${this.projectId}/project-details/queues/workspaceList`
        );
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
  public saveWorkspace = async (formData: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      if (this.customerId && this.projectId) {
        const response = await axios.post(
          serverUrl +
            `${this.customerId}/${this.projectId}/project-details/queues/addNewWorkspace`,
          formData
        );
        return response;
      } else {
        return {} as any;
      }
    } catch (error) {
      return error as any;
    }
  };
}
