import axios from 'axios';
import { history } from 'react-router-guard';
import { API_URL, FRONTEND_URL } from '../../config';
import { getCustomerIdAndProjectIdFromURL } from '../../helpers';
import { OnBoardingService } from '../../services/onBoarding/onBoarding.service';

const submitCustomer = () => {
  localStorage.removeItem('customerId');
  const url = `${API_URL}/customers/new`
  axios.post(url).then((res) => {
      window.location.href = `${FRONTEND_URL}/en/customer/${res.data.id}/project/default`
    });
};

const requireOnboarding = async () => {
  // console.log("on Boarding");
  const onBoardingService = new OnBoardingService();
  const checkAPIObject = await onBoardingService.checkAPI();
  if((checkAPIObject === null) || (checkAPIObject && checkAPIObject.length === 0)){
    submitCustomer()
  }
  const getObject = localStorage.getItem('customerId') ? checkAPIObject.filter((res: any)=>
    res.id === localStorage.getItem('customerId')
  ).length === 0 ? checkAPIObject.reverse() : checkAPIObject.filter((res: any)=>
  res.id === localStorage.getItem('customerId')
) : checkAPIObject.reverse();

  const getPro = await onBoardingService.getProjects(getObject[0].id);
  console.log("checkAPIObject",checkAPIObject)
  // localStorage.getItem('customerId');
  
  console.log("getObject",getObject)
  const path: string[] = history.location.pathname.split('/');
  // console.log("checkAPIObject",path)
  const lang = path[1];
  return new Promise((resolve, reject) => {
    try {
      console.log("getObject[0]",getObject,getPro)
      if (getObject[0].onboarding.status === 'finished') {
        const [customerid, projectid] = getCustomerIdAndProjectIdFromURL(
          getObject[0].id,
          getPro[0].projectId,
          path,
        );
        
        console.log("projectid",projectid)
        const mainDashboardRoute = `/${lang}/customer/${customerid}/project/${projectid}`;
        if (!history.location.pathname.includes(mainDashboardRoute)) {
          history.push({
            pathname: `/${lang}/customer/${customerid}/project/${projectid}`,
          });
        }
        resolve({
          projects: getObject[0].projects,
          name: getObject[0].name,
          customerId: getObject[0].id,
        });
      } else {
        history.push({ pathname: `/${lang}/onboarding` });
        resolve(getObject[0]);
      }
    } catch (error) {
      history.push({ pathname: `/${lang}/onboarding` });
      return resolve({});
    }
  });
};

export default requireOnboarding;
