import React from "react";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface SnackbarMessages {
  open: boolean;
  handleClose?: () => void;
  type?: string;
  message: string;
}

const SnackbarComponent = ({
  open,
  handleClose,
  type,
  message,
}: SnackbarMessages) => {
  const useStyles = makeStyles({
    myAlert: {
      backgroundColor: "#24c0dc",
    },
  });
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        style={{ fontSize: "1rem" }}
        severity={type ? type : "success"}
        {...(type === "success" || !type ? { className: classes.myAlert } : {})}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
