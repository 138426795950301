import { SessionStorageService } from '../../services/session-storage/session-storage';
import { history } from 'react-router-guard';
const sessionStorageService: SessionStorageService = new SessionStorageService();

const requireLogout = () => {
  const path: string[] = history.location.pathname.split('/');
  const lang = path[1];
  return new Promise((resolve, reject) => {
    try {
      if (sessionStorageService.getData('token')) {
        history.push({ pathname: `/${lang}/onboarding` });
        reject();
      } else {
        resolve(sessionStorageService.getData('token'));
      }
    } catch (error) {
      history.push({ pathname: `/${lang}` });
      return reject();
    }
  });
};

export default requireLogout;
