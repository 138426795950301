export enum DocumentTypes {
  WAYBILL = 'waybill',
  INVOICE = 'invoice',
  DELIVERY_NOTE = 'delivery note',
  ORDER_CONFIRMATION = 'order confirmation',
  PACKING_LIST = 'packing list',
  PREFERENCE_DOCUMENT = 'preference document',
  RECORD_OF_PAYMENT = 'recored of payment',
  OTHERS = 'others',
}
export interface ProcessedDocument {
  label: DocumentTypes;
  active: boolean;
  value: string | string[];
}

export interface ProcessedDocumentsTabForm {
  processedDocuments: ProcessedDocument[];
}
