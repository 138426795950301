import { SelectedDocs } from "../../types/dexter-doc-list";

export interface DataExtractionState {
  isDocUpdated: boolean;
  seletedQueue: any;
  messagesPopup: {
    isOpen: boolean;
    Message: string;
    type?: string;
  };
  numberOfPages: number;
  pageNumbers: number;
  selectedDocuments: SelectedDocs;
  dexterInputAccurary: any;
  dexterFilledOut: any;
  dexterInputFields: any;
  dexterLineConnector: any;
  dexterScrollIntoView: any;
  isQueueListUpdated: boolean;
}

// Define the initial state using that type
export const initialState: DataExtractionState = {
  seletedQueue: {},
  messagesPopup: {
    isOpen: false,
    Message: "",
    type: "success",
  },
  numberOfPages: 0,
  pageNumbers: 0,
  isDocUpdated: false,
  selectedDocuments: {},
  dexterInputAccurary: "",
  dexterFilledOut: "",
  dexterInputFields: null,
  dexterLineConnector: "",
  dexterScrollIntoView: null,
  isQueueListUpdated: false,
};
