import { history } from 'react-router-guard';

const requireLanguage = () => {
  return new Promise((resolve, reject) => {
    try {
      const path: string[] = history.location.pathname.split('/');
      if (path.length > 1) {
        if (path[1] !== 'en' && path[1] !== 'de') {
          path[1] = 'en';
        }
        if (path.length === 2) {
          path.push('login');
        }
        history.push(path.join('/'));
        resolve(path);
      } else {
        history.push('/en/login');
        resolve('/en/login');
      }
    } catch (error) {
      history.push('/en/login');
      return reject();
    }
  });
};

export default requireLanguage;
