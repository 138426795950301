import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "./initialState";

export const importSlice = createSlice({
    name: "import",
    initialState,
    reducers: {


        // Waybill
        setWaybillSender: (state, { payload }: PayloadAction<any>) => {
            state.waybillSender = payload
        },
        setWaybillRecipient: (state, { payload }: PayloadAction<any>) => {
            state.waybillRecipient = payload
        },
        setWaybillShipmentInformation: (state, { payload }: PayloadAction<any>) => {
            state.waybillShipmentInformation = payload
        },
        setWaybillApplicant: (state, { payload }: PayloadAction<any>) => {
            state.waybillApplicant = payload
        },
        setWaybillPreliminaryDocuments: (state, { payload }: PayloadAction<any>) => {
            state.waybillPreliminaryDocuments = payload
        },
        setWaybillFreightDetails: (state, { payload }: PayloadAction<any>) => {
            state.waybillFreightDetails = payload
        },

        // Invoices
        setInvoiceSender: (state, { payload }: PayloadAction<any>) => {
            state.invoiceSender = payload
        },
        setInvoiceGoodRecipient: (state, { payload }: PayloadAction<any>) => {
            state.invoiceGoodRecipient = payload
        },
        setInvoiceRecipient: (state, { payload }: PayloadAction<any>) => {
            state.invoiceRecipient = payload
        },
        setInvoiceShipmentInformation: (state, { payload }: PayloadAction<any>) => {
            state.invoiceShipmentInformation = payload
        },
        setInvoiceItems: (state, { payload }: PayloadAction<any>) => {
            state.invoiceItems = payload
        },
        pushInvoiceItems: (state, { payload }: PayloadAction<any>) => {
            console.log(payload, 'payload love')
            // const states = state?.invoiceItems?.data[0];
            // console.log("payload", payload, states, 'items')
            // states.items[0].splice(payload?.index, 1, payload?.row)
            state.invoiceItems.data[0] = payload?.row
        },

        // Cost Overview 
        setCostOverview: (state, { payload }: PayloadAction<any>) => {
            state.costOverview = payload
        },

        // Packaging List 
        setPackagingListSender: (state, { payload }: PayloadAction<any>) => {
            state.packagingListSender = payload
        },
        setPackagingListGoodRecipient: (state, { payload }: PayloadAction<any>) => {
            state.packagingListGoodRecipient = payload
        },
        setPackagingListRecipient: (state, { payload }: PayloadAction<any>) => {
            state.packagingListRecipient = payload
        },
        setPackagingListShipmentInformation: (state, { payload }: PayloadAction<any>) => {
            state.packagingListShipmentInformation = payload
        },
        setPackagingListItems: (state, { payload }: PayloadAction<any>) => {
            state.packagingListItems = payload
        },

        // right sidebar
        setSidebar: (state, { payload }: PayloadAction<any>) => {
            state.sidebar = payload
        },
        setIds: (state, { payload }: PayloadAction<any>) => {
            const ids = state.ids
            payload.forEach((item: any) => ids.push(item))
            const uniqueIds = ids.filter((c: any, index: any) => ids.indexOf(c) === index);
            state.ids = uniqueIds
        },
        setAllIds: (state, { payload }: PayloadAction<any>) => {
            const ids = state.allIds
            payload.forEach((item: any) => ids.push(item))
            const uniqueIds = ids.filter((c: any, index: any) => ids.indexOf(c) === index);
            state.allIds = uniqueIds
        },
        pushIds: (state, { payload }: PayloadAction<any>) => {

            const ids = state.allIds
            const start = ids.findIndex((ele: any) => ele === payload.id)
            ids.splice(start + 1, 0, ...payload.childIds)
            const uniqueIds = ids.filter((c: any, index: any) => ids.indexOf(c) === index);
            state.allIds = uniqueIds
        },
        setId: (state, { payload }: PayloadAction<any>) => {
            console.log(payload, 'event payload')
            state.id = payload
        },
        setSavingArray: (state, { payload }: PayloadAction<any>) => {
            const states = state.savingArray
            console.log(states, 'xyz payload')
            if (states.length > 0) {
                states.map((ele: { path: any; value: any; }) => {
                    if (ele.path === payload.path) {
                        ele.value = payload.value
                    } else {
                        states.push(payload)
                    }
                    return 1;
                })
                const unique = states.filter((v: { path: any; }, i: any, a: any[]) => a.findIndex((t: any) => (t.path === v.path)) === i)
                state.savingArray = unique
            } else {
                states.push(payload)
                state.savingArray = states
            }
        },
        resetSavingArray: (state, { payload }: PayloadAction<any>) => {
            state.savingArray = payload
        },
        setInputIds: (state, { payload }: PayloadAction<any>) => {
            state.inputIds = []
            state.inputIds = payload
        }
    },
});

export const {

    // Waybill
    setWaybillSender,
    setWaybillRecipient,
    setWaybillShipmentInformation, // data not set now
    setWaybillApplicant,
    setWaybillPreliminaryDocuments,
    setWaybillFreightDetails,

    // Invoices
    setInvoiceSender,
    setInvoiceGoodRecipient,
    setInvoiceRecipient,
    setInvoiceShipmentInformation,
    setInvoiceItems,
    pushInvoiceItems,

    // Cost Overview 
    setCostOverview, // data not set now

    // Packaging List 
    setPackagingListSender,
    setPackagingListGoodRecipient,
    setPackagingListRecipient,
    setPackagingListShipmentInformation,
    setPackagingListItems,

    // right sidebar
    setSidebar,
    setIds,
    setAllIds,
    pushIds,
    setId,

    setSavingArray,
    resetSavingArray,

    setInputIds
} = importSlice.actions

export default importSlice.reducer;