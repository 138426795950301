import {
  ThemeProvider,
  CssBaseline,
  Backdrop,
  CircularProgress,
} from "@material-ui/core"
import React, { useMemo, useEffect } from "react"
import { theme } from "./theme"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import { config } from "./config"
import {
  MsalProvider,
  useIsAuthenticated,
  useMsal,
  useAccount,
} from "@azure/msal-react";
import io from "socket.io-client";
import './App.css'
import { PublicClientApplication } from "@azure/msal-browser";
import { SessionStorageService } from "./services/session-storage/session-storage";
import getRoutes from "./router/routes";
import useInterval from "./useHooks/useInterval";
import { setStepOne } from "./store/onboarding";
import { setMessagePopup, setSockets } from "./store/dashboard";
import { useAppDispatch } from "./store";
import { RouterGuard } from "react-router-guard";
import SnackbarComponent from "./components/common-components/SnakbarMessages";
import Loader from "./components/common-components/Loader";
import { useAppSelector } from "./store";

function App() {
  useEffect(() => console.log("config", config.msalConfig), [])
  return (
    <MsalProvider instance={new PublicClientApplication(config.msalConfig)}>
      <AppIndex />
    </MsalProvider>
  )
}
const AppIndex = () => {
  const dispatch = useAppDispatch()
  const routes = useMemo(() => getRoutes(), [])
  const sessionStorageService: SessionStorageService =
    new SessionStorageService()
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts } = useMsal()
  const account = useAccount(isAuthenticated ? accounts[0] : {})
  // const isSupportedScreen = useMediaQuery("(min-width:1280px)")
  const {
    messagesPopup: { isOpen, Message, type },
    cicularLoader,
  } = useAppSelector((state) => state.dashboard);
  const { customerId, projects, currentProjectIndex } = useAppSelector(
    (state) => state.onboarding
  );

  // establishing socket io connection
  useEffect(() => {
    if (!customerId || !projects[currentProjectIndex] || !projects[currentProjectIndex]?.projectId) return;
    const socket = io("https://socket.api.digicust.com", {
      // path: "/generic/socket.io",
      query: {
        customerId,
        projectId: projects[currentProjectIndex]?.projectId
      },
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: sessionStorageService.getData("token"),
            "x-digicust-user-id": customerId
          }
        }
      }
    });
    socket.on('disconnect', function() {
      console.log("disconnect");
      // todo token error connection needs to drop here
    });
    dispatch(setSockets(socket));

    return () => {
      dispatch(setSockets(undefined));
      socket.off("disconnect");
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customerId, projects, currentProjectIndex]);

  // Refresh Token every hour
  useInterval(
    () => {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: ["openid c41cebcc-2a6c-4e60-a7d4-3e37c4f19ffb offline_access"],
            account: account,
          })
          .then((response) => {
            if (response) {
              sessionStorageService.setData(
                "token",
                `Bearer ${response.idToken}`
              )
              if (!sessionStorageService.getData("account")) {
                sessionStorageService.setData("account", response.account)
              }
            }
          })
          .catch((err) => {
            console.log("token error", err)
            instance.logout()
            sessionStorageService.clearAll()
          })
      }
    },
    // Delay in milliseconds (1 hour)
    3599000
  )

  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: ["openid c41cebcc-2a6c-4e60-a7d4-3e37c4f19ffb offline_access"],
          account: account,
        })
        .then((response) => {
          if (response) {
            console.log("response!!", response)
            sessionStorageService.setData("token", `Bearer ${response.idToken}`)

            const accountStored =
              sessionStorageService.getData("account") || response.account
            if (!sessionStorageService.getData("account")) {
              sessionStorageService.setData("account", response.account)
            }
            console.log("account stored", accountStored)
            const [firstName, lastName] =
              (accountStored?.name || "") &&
              (accountStored?.name.split(" ") || "")
            dispatch(
              setStepOne({
                firstName,
                lastName,
                email: accountStored.username,
                acceptTerms: false,
                subscribeToNewsletter: false,
              })
            )
            if (window.location.pathname.includes("login")) {
              window.location.reload()
              console.log("reloaded bc of request")
            }
          }
        })
        .catch((err) => {
          console.log("token error", err)
          instance.logout()
          sessionStorageService.clearAll()
        })
    } else {
      console.log("no account")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, instance])
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarComponent
          open={isOpen}
          message={Message}
          type={type}
          handleClose={() =>
            dispatch(
              setMessagePopup({
                isOpen: false,
                type,
                Message,
              })
            )
          }
        />
        <Loader isOpen={cicularLoader} />
        {/* {!isSupportedScreen && (
          <Box
            display='block'
            position='fixed'
            width='100vw'
            height='100vh'
            background-color='#0282B4'
            top='0px'
            left='0px'
            zIndex={9999999}
            fontSize='1.25rem'
            padding='50px'
            color='#fff'
            bgcolor='#122a51'
          >
            Not Supported Resolution
          </Box>
        )} */}
        <RouterGuard
          config={routes}
          loading={
            <Backdrop open={true} style={{ zIndex: 100000 }}>
              <CircularProgress color='primary' />
            </Backdrop>
          }
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export default App
