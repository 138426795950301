import { UploadDialogTypes } from "../../types/upload-dialog";

export interface DashboardState {
  socket?: {
    current: any;
  };
  uploadStatus: {
    isDragActive: boolean;
    type: UploadDialogTypes;
    moduleType: string;
  };
  moduleInfo: {
    moduleType?: string;
    moduleCategory?: string;
    queueId?: string;
  };
  allowedClassifications: string[];
  SeletedQueue: any;
  messagesPopup: {
    isOpen: boolean;
    Message: string;
    type?: string;
  };
  cicularLoader: boolean;
  cachedData: any;
  caseNumber: number;
  updatedNavItems: any;
}

// Define the initial state using that type
export const initialState: DashboardState = {
  uploadStatus: {
    isDragActive: false,
    type: UploadDialogTypes.EXISTING_CASE,
    moduleType: "",
  },
  moduleInfo: {
    moduleType: "",
    moduleCategory: "",
    queueId: "",
  },
  allowedClassifications: [],
  SeletedQueue: {},
  messagesPopup: {
    isOpen: false,
    Message: "",
    type: "success",
  },
  cicularLoader: false,
  cachedData: {},
  caseNumber: 0,
  updatedNavItems: [], //moduleSettings
};
