export const companySizes = ['1-10', '11-50', '51-250', '251-1000', '> 1000'];
export const numbersOfCustomers = [
  '1-10',
  '11-100',
  '101-250',
  '251-500',
  '500-1000',
  '1000-10000',
  '>10000',
];

export const usesOfSoftware = [
  { label: 'For own company', value: 'company' },
  { label: 'For customs clearance services', value: 'service' },
];
export const imports = ['1-10', '11-50', '51-250', '251-1000', '> 1000'];
export const exports = ['1-10', '11-50', '51-250', '251-1000', '> 1000'];
export const proceduresOfYear = [
  '1-10',
  '11-50',
  '51-250',
  '251-1000',
  '> 1000',
];
export const proceduresTags = [
  {
    label: 'Transit Declarations (NCTS)',
    value: 'transit declarations (ncts)',
  },
  { label: 'Inward processing', value: 'inward processing' },
  { label: 'Outward processing', value: 'outward processing' },
  { label: 'Temporary usage', value: 'temporary usage' },
  { label: 'Bounded Warehouse', value: 'bounded warehouse' },
];
