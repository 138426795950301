import {} from "./../../types/upload-dialog";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UploadDialogTypes } from "../../types/upload-dialog";

import { initialState } from "./initialState";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setIsDragActive: (state, { payload }: PayloadAction<boolean>) => {
      state.uploadStatus.isDragActive = payload;
    },
    setUploadType: (state, { payload }: PayloadAction<UploadDialogTypes>) => {
      state.uploadStatus.type = payload;
    },
    setUploadModuleType: (state, { payload }: PayloadAction<any>) => {
      state.moduleInfo = payload;
    },
    setAllowedClassifications: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.allowedClassifications = payload;
    },
    setSeletedQueue: (state, { payload }: PayloadAction<any>) => {
      state.SeletedQueue = payload;
    },
    setMessagePopup: (state, { payload }: PayloadAction<any>) => {
      state.messagesPopup = payload;
    },
    setLoader: (state, { payload }: PayloadAction<any>) => {
      state.cicularLoader = payload;
    },
    setCachedData: (state, { payload }: PayloadAction<any>) => {
      state.cachedData = {
        ...state.cachedData,
        ...payload,
      };
    },
    setCaseNumber: (state, { payload }: PayloadAction<any>) => {
      state.caseNumber = payload;
    },
    setSockets: (state, { payload }: PayloadAction<any>) => {
      state.socket = {
        current: payload,
      };
    },
    setNavItemUpdate: (state, { payload }: PayloadAction<any>) => {
      state.updatedNavItems = payload;
    },
  },
});

export const {
  setIsDragActive,
  setUploadType,
  setUploadModuleType,
  setAllowedClassifications,
  setSeletedQueue,
  setMessagePopup,
  setLoader,
  setCachedData,
  setCaseNumber,
  setSockets,
  setNavItemUpdate,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
