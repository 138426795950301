
export interface transitState {
    shipper: any;
    consignee: any;
    shipmentInformation: any;
    obligatedParty: any;
    preliminaryDocuments: any;
    freightDetails: any;
    sidebar: any
}

export const initialState = {
    shipper: null,
    consignee: null,
    shipmentInformation: null,
    obligatedParty: null,
    preliminaryDocuments: null,
    freightDetails: null,
    transitItems: {
        data: [] as any
    },
    sidebar: {
        id: null,
        data: null
    },
    id: null,  // selected id 
    ids: [] as any, // all left ids 
    allIds: [] as any, // all ids include both left and right(input) ids
    inputIds: [] as any,
    savingArray: [] as any,
}